import { EAlarmStatus } from '~/components/Alarms/Alarms.types'
import * as Yup from 'yup'
import {
  EBillingSeries,
  EBillingServices,
  EFrequency,
} from '~/components/Settings/Settings.types'
import { EMode } from '~/components/Storage/Storages.types'
import { ConstraintTypes } from '~/components/ResourceConstraints/Constraints.types.'
import { EType } from '~/components/Resources/Resources.types'
import {
  AvailableActions,
  CardType,
  DraftType,
} from '~/components/PluginsAndWebscripts/Common/Types'

const { WAYLAY_DOCS_URL } = window.env

export const COLUMN_MIN_WIDTH = 1181
export const MINIMUM_DESKTOP_WIDTH = 1180

// DATA INGESTION
export const BROKER_DOCS_URL = `${WAYLAY_DOCS_URL}/#/api/broker/?id=data-ingestion-api`
export const WEBSCRIPTS_DOCS_URL = `${WAYLAY_DOCS_URL}/#/features/data_ingestion/webscripts/`

export const EDGE_MODE_UNSUPPORTED_INTEGRATION_TEMPLATES = ['arm']

export const REFRESH_STATUS_INTERVAL_INTEGRATIONS_LIST = 15000

export const REFRESH_CONFIG_INTERVAL_INTEGRATIONS = 5000

// LANDING PAGE
export const LANDING_PAGE_GENERIC_MESSAGES = ['Welcome back', 'Hi there']

export const CAROUSEL_RESPONSIVENESS_CONFIG = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 1301, min: 800 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

// USE BATCH
export const USE_BATCH_POLLING_INTERVAL = 2000
export const USE_BATCH_POLLING_TIMEOUT = 2 * 60 * 1000 // stop polling after 2 minutes have elapsed

// USE PAGINATION
export const PAGINATION_DEFAULT_INITIAL_PAGE = 0
export const PAGINATION_DEFAULT_LIMIT = 25

// ALARMS
export const QUERY_BUILDER_ALARMS_DATA = [
  {
    type: 'scheduled',
    status: 'active',
  },
  {
    type: 'periodic',
    status: 'acknowledged',
  },
  {
    type: 'one time',
    status: 'cleared',
  },
]

export const QUERY_BUILDER_ALARMS_OPTIONS = [
  {
    columnField: 'status',
    type: 'selection',
  },
  {
    columnField: 'source',
    type: 'text',
  },
  {
    columnField: 'dateTo',
    type: 'text',
  },
  {
    columnField: 'dateFrom',
    type: 'text',
  },
  {
    columnField: 'type',
    type: 'text',
  },
]

export const QUERY_BUILDER_ALARMS_SUGGESTIONS = [
  {
    key: 'status',
    description: 'filter by status',
    options: [
      {
        key: EAlarmStatus.Active.toLowerCase(),
        label: EAlarmStatus.Active.toLowerCase(),
        description: 'Active alarms',
      },
      {
        key: EAlarmStatus.Acknowledged.toLowerCase(),
        label: EAlarmStatus.Acknowledged.toLowerCase(),
        description: 'Acknowledged alarms',
      },
      {
        key: EAlarmStatus.Cleared.toLowerCase(),
        label: 'resolved',
        description: 'Resolved alarms',
      },
    ],
  },
  {
    key: 'source',
    description: 'the source of the alarm',
  },
  {
    key: 'dateFrom',
    description: 'filter by <timestamp> greater than or equal to given date',
  },
  {
    key: 'dateTo',
    description: 'filter by <timestamp> less than or equal to given date',
  },
  {
    key: 'type',
    description: 'filter by type',
  },
]

export const ALARM_STATUS_TEXT = {
  ACTIVE: 'active',
  ACKNOWLEDGED: 'acknowledged',
  CLEARED: 'resolved',
}

// SETTINGS
export const SETTINGS_ROOT = '/administration/settings'
export const ORGANISATION_ROOT = `${SETTINGS_ROOT}/organisation`
export const TEAM_ROOT = `${ORGANISATION_ROOT}/team`

export const ADD_ROLE_SCHEMA_VALIDATION = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string(),
})

export const OPERATIONS_LIST = [
  { label: 'Create', value: 'create' },
  { label: 'Read', value: 'read' },
  { label: 'Update', value: 'update' },
  { label: 'Delete', value: 'delete' },
]

export const SCOPES_LIST = [
  { label: 'All', value: 'all' },
  { label: 'Me', value: 'me' },
]

export const EXPORT_DEFAULT_SCHEDULE = {
  format: 'csv',
  compressed: true,
  period: 'P1D',
  scheduleExpression: 'FREQ=DAILY;INTERVAL=1',
  timeZone: 'Europe/Brussels',
  meta: false,
  timeseries: true,
}

export const EXPORT_FREQUENCY_PERIODS = {
  [EFrequency.Daily]: 'P1D',
  [EFrequency.Weekly]: 'P1W',
  [EFrequency.Monthly]: 'P1M',
}

export const CREATE_SCHEDULE_DEFAULT_VALUES = {
  frequency: undefined,
  includeMeta: false,
}

export const CREATE_SCHEDULE_FREQUENCY_OPTIONS = [
  { label: 'Every day', value: EFrequency.Daily },
  { label: 'Every week', value: EFrequency.Weekly },
  { label: 'Every month', value: EFrequency.Monthly },
]

export const METRIC_REQUEST_MAP = {
  [EBillingServices.Alarms]: [EBillingSeries.alarmsOccurred],
  [EBillingServices.Vault]: [EBillingSeries.vaultKeys],
  [EBillingServices.Storage]: [
    EBillingSeries.storageTotalBytes,
    EBillingSeries.storageTotalBytesReceived,
    EBillingSeries.storageTotalBytesSent,
  ],
  [EBillingServices.Functions]: [
    EBillingSeries.functionComputeTime,
    EBillingSeries.functionExecutions,
    EBillingSeries.nativeFunctionExecutions,
    EBillingSeries.nativeFunctionComputeTime,
  ],
  [EBillingServices.TimeSeries]: [
    EBillingSeries.ingestedDatapoints,
    EBillingSeries.timeseriesReadScan,
  ],
  [EBillingServices.Broker]: [
    EBillingSeries.messagesIngested,
    EBillingSeries.messagesQueried,
    EBillingSeries.messagesStorageSize,
  ],
  [EBillingServices.Tasks]: [
    EBillingSeries.taskTransitions,
    EBillingSeries.taskLogsStorage,
    EBillingSeries.taskInvocations,
    EBillingSeries.templateRunInvocations,
  ],
  [EBillingServices.Assets]: [
    EBillingSeries.resources,
    EBillingSeries.resourceTypes,
    EBillingSeries.resourceConstraints,
    EBillingSeries.resourceStorageSize,
  ],
  [EBillingServices.MachineLearning]: [EBillingSeries.byomlComputeTime],
  [EBillingServices.Webscripts]: [
    EBillingSeries.webscriptExecutions,
    EBillingSeries.webscriptComputeTime,
  ],
  [EBillingServices.TotalInvocations]: [EBillingSeries.totalInvocations],
  [EBillingServices.runningTasks]: [EBillingSeries.runningTasks],
}

// STORAGE
export const EDIT_FILTERS_DEFAULT_VALUES = {
  onFileChange: true,
  onFileDelete: true,
}

export const TRIGGER_BUTTON = {
  [EMode.add]: {
    label: 'Add a filter',
    icon: 'add_circle_outline',
    kind: 'primary',
  },
  [EMode.edit]: {
    label: 'Edit',
    icon: 'edit',
    kind: 'secondary',
  },
}
export const HTTP_STATUS_CODES = {
  BAD_REQUEST: 400,
}

export const API_ERRORS = {
  EXCHANGE_TOKEN_EXPIRED: 'Exchange token is expired',
}

export const RESOURCES_INPUT_TYPES = {
  REFERENCE: 'reference',
  STRING: 'string',
  BOOLEAN: 'boolean',
  OBJECT: 'object',
  NUMBER: 'numeric',
}

export const DEFAULT_VALUE_INPUT = {
  string: '',
  object: JSON.parse('{}'),
  numeric: 0,
}

export const RESOURCES_INPUT_TYPES_ARRAY = [
  {
    type: 'string',
    representation: 'abc',
  },
  {
    type: 'boolean',
    representation: 'T/F',
  },
  {
    type: 'object',
    representation: '{}',
  },
  {
    type: 'numeric',
    representation: '123',
  },
  {
    type: 'reference',
    representation: 'REF',
  },
]

export const METADATA_FILTER = [
  // these would be redundant
  'id',
  'name',
  'resourceTypeId',
  'constraintsDetails',
  'constraintsList',
  // we will visualize these in their own way
  // (Gilles): we should probably expose these until we have a better UI in place
  '_links',
]

export const METADATA_BLACKLIST = [
  // these would be redundant
  'id',
  'name',
  'templates',
  '$constraints',
  'constraints',
  'resourceTypeConstraintsDetails',
  '$bulkOperation', // for some reason this one is returned in the HTTP response when updating
]

export const DEFAULT_INITIAL_VALUES = {
  name: '',
  required: false,
  type: '',
  elementType: '',
  enumType: '',
  items: [],
  contains: [],
  attributes: [],
}

export const ATTRIBUTE_TYPES_OPTIONS = [
  { label: 'numeric', value: ConstraintTypes.numeric },
  { label: 'string', value: ConstraintTypes.string },
  { label: 'boolean', value: ConstraintTypes.boolean },
  { label: 'object', value: ConstraintTypes.object },
  { label: 'array', value: ConstraintTypes.array },
  { label: 'enum', value: ConstraintTypes.enum },
  { label: 'resourceRef', value: ConstraintTypes.resourceRef },
]

export const ARRAY_ELEMENT_TYPES_OPTIONS = [
  { label: 'numeric', value: ConstraintTypes.numeric },
  { label: 'string', value: ConstraintTypes.string },
  { label: 'boolean', value: ConstraintTypes.boolean },
  { label: 'object', value: ConstraintTypes.object },
]

export const ENUM_TYPES_OPTIONS = [
  { label: 'numeric', value: ConstraintTypes.numeric },
  { label: 'string', value: ConstraintTypes.string },
]

export const RESOURCE_RESOURCE_TYPE_TABS = {
  [EType.resource]: [
    { name: 'Details', to: `meta` },
    { name: 'Data', to: `data` },
    {
      name: 'Relations',
      to: `relations`,
    },
    {
      name: 'History',
      to: `history`,
    },
  ],
  [EType.resourceType]: [
    {
      name: 'Details',
      to: `meta`,
    },
    {
      name: 'Templates',
      to: `templates`,
    },
    {
      name: 'Constraints',
      to: `constraints`,
    },
    {
      name: 'History',
      to: `history`,
    },
  ],
}

export const QUERY_BUILDER_RESOURCES_SUGGESTIONS = [
  {
    key: 'id',
    description: 'Filter by resource identifier',
  },
  {
    key: 'name',
    description: 'Filter by resource name',
  },
  {
    key: 'resourceTypeId',
    description: 'Filter by resource type id',
  },
  {
    key: 'tags',
    description: 'Filter by one or many matching tags',
  },
  {
    key: 'provider',
    description: 'Filter by provider',
  },
  {
    key: 'customer',
    description: 'Filter by customer',
  },
]

export const EMPTY_TAGS = [{ key: '', value: '' }]

export const GATE_DEFAULT_STATES = ['TRUE', 'FALSE']
export const TAG_PREFIX = 'tags:'

export const GATE_NODE_TYPE = 'gate'
export const GATE_TRUE_COLOR = '#23A221'
export const GATE_FALSE_COLOR = '#D8241E'
export const LABELS_WIDTH = 100

export const QUERY_BUILDER_TYPES = {
  ALARMS: 'alarms',
  RESOURCES: 'resources',
  TASKS: 'tasks',
  TEMPLATE: 'template',
}

export const PROPERTIES_LAYOUT = {
  tickTrigger: 'Tick trigger',
  dataTrigger: 'Data trigger',
}

export const CONFIGURATIONS_LAYOUT = {
  cost: 'Sequence',
  pollingPeriod: 'Polling period',
  evictionTime: 'Eviction time',
  timeout: 'Timeout',
  timeoutState: 'Timeout state',
  resource: 'Bound resource',
  policy: 'Invocation policy',
  loopDef: 'Loop Definition',
}

export const QUERY_BUILDER_RESOURCES_OPTIONS = [
  {
    columnField: 'name',
    type: 'text',
  },
  {
    columnField: 'tags',
    type: 'text',
  },
  {
    columnField: 'id',
    type: 'text',
  },
  {
    columnField: 'provider',
    type: 'text',
  },
  {
    columnField: 'customer',
    type: 'text',
  },
  {
    columnField: 'resourceTypeId',
    type: 'text',
  },
]

export const QUERY_BUILDER_TASKS_DATA = [
  {
    type: 'scheduled',
    status: 'running',
  },
  {
    type: 'periodic',
    status: 'stopped',
  },
  {
    type: 'one time',
    status: 'fail',
  },
  {
    type: 'reactive',
    status: undefined,
  },
]
export const QUERY_BUILDER_TEMPLATE_OPTIONS = [
  {
    columnField: 'id',
    type: 'text',
  },
  {
    columnField: 'plugin',
    type: 'text',
  },
  {
    columnField: 'createdBefore',
    type: 'text',
  },
  {
    columnField: 'createdAfter',
    type: 'text',
  },
  {
    columnField: 'updatedBefore',
    type: 'text',
  },
  {
    columnField: 'updatedAfter',
    type: 'text',
  },
]
export const QUERY_BUILDER_TASKS_OPTIONS = [
  {
    columnField: 'name',
    type: 'text',
  },
  {
    columnField: 'resource',
    type: 'text',
  },
  {
    columnField: 'type',
    type: 'selection',
  },
  {
    columnField: 'status',
    type: 'selection',
  },
  {
    columnField: 'id',
    type: 'text',
  },
  {
    columnField: 'plugin',
    type: 'text',
  },
  {
    columnField: 'template',
    type: 'text',
  },
  {
    columnField: 'tags',
    type: 'text',
  },
  {
    columnField: 'finishedBefore',
    type: 'text',
  },
  {
    columnField: 'createdAfter',
    type: 'text',
  },
  {
    columnField: 'createdBefore',
    type: 'text',
  },
]

export enum PluginView {
  LIST = 'list',
  DETAILS = 'details',
}

export const CardTypeToActions = {
  [CardType.PUBLISHED]: {
    [PluginView.LIST]: {
      [AvailableActions.DOWNLOAD]: true,
      [AvailableActions.DEPRECATE_ALL]: true,
      [AvailableActions.DELETE_ALL]: true,
      [AvailableActions.COPY_AS]: true,
      [AvailableActions.UNPROTECT_ALL]: true,
      [AvailableActions.PROTECT_ALL]: true,
      [AvailableActions.REDEPLOY]: true,
    },
    [PluginView.DETAILS]: {
      [AvailableActions.DOWNLOAD]: true,
      [AvailableActions.DEPRECATE]: true,
      [AvailableActions.DELETE]: true,
      [AvailableActions.COPY_AS]: true,
      [AvailableActions.UNPROTECT_ALL]: true,
      [AvailableActions.PROTECT_ALL]: true,
      [AvailableActions.REDEPLOY]: true,
    },
  },
  [DraftType.OWNED]: {
    [AvailableActions.DOWNLOAD]: true,
    [AvailableActions.DELETE_DRAFT]: true,
    [AvailableActions.PUBLISH]: true,
    [AvailableActions.COPY_AS]: true,
    [AvailableActions.UNPROTECT_ALL]: true,
    [AvailableActions.PROTECT_ALL]: true,
    [AvailableActions.REDEPLOY]: true,
  },
  [DraftType.NOT_OWNED]: {
    [AvailableActions.DOWNLOAD]: true,
    [AvailableActions.COPY_AS]: true,
    [AvailableActions.UNPROTECT_ALL]: true,
    [AvailableActions.PROTECT_ALL]: true,
    [AvailableActions.REDEPLOY]: true,
  },
}

export const LOADER_INSTANCES = 6
export const { WAYLAY_APPLICATIONS_KEY } = window.env

export const ALARMS_SORTABLE_FIELDS = [
  { label: 'Timestamp', value: 'timestamp' },
  { label: 'Last Updated', value: 'lastUpdateTime' },
  { label: 'Last Triggered', value: 'lastTriggeredTime' },
]

export const TASKS_SORTABLE_FIELDS = [
  { value: 'creationtime', label: 'Created At' },
  { value: 'finishtime', label: 'Finished At' },
]

export const TEMPLATE_SORTABLE_FIELDS = [
  { value: 'creationtime', label: 'Created At' },
  { value: 'updatetime', label: 'Updated At' },
]

export const PLUGIN_EXCLUDED = ['name', 'type', 'version', 'parameters']

export const TEMPLATE_EXCLUDED = ['name', 'type', 'parameters']

export const PARAMETER_EXCLUDED = [
  'name',
  'type',
  'items',
  'format',
  'required',
  'allowedValues',
  'overrideValue',
]

export const DEFAULT_VERSION = '1.0.0'
export const CONTENT_RESPONSE_TYPE = {
  BLOB: 'blob',
  JSON: 'json',
} as const
export const MIME_TYPE = {
  JSON: 'application/json',
  GZIP: 'application/javascript',
  TEXT: 'text/plain',
}
export const FUNCTION_RUNTIME = {
  NODE: 'node',
  PYTHON: 'python',
}
export const FUNCTION_TAGS_RENDERING = {
  INLINE: 'inline',
}
