import semver from 'semver'
import { find, uniqBy, pick, sortBy } from 'lodash-es'

export interface IPlug {
  name: string
  version: string
  type: 'sensor' | 'actuator'
}

export interface IAvailablePlugUpgrade extends IPlug {
  newerVersion: string
  enabled?: boolean
}

export function findPlugUpdates(
  plugsInUse = [],
  latestPlugs = [],
): IAvailablePlugUpgrade[] {
  return plugsInUse.reduce((acc, plug) => {
    const { name, version: currentVersion } = plug

    const latestPlug = find(latestPlugs, { name })

    if (!latestPlug) return acc
    const hasNewer = semver.gt(latestPlug.version, currentVersion)

    const canBeUpdated = latestPlug && hasNewer
    return canBeUpdated
      ? acc.concat({
          name,
          version: currentVersion,
          newerVersion: latestPlug.version,
          type: plug.type,
        })
      : acc
  }, [])
}

export function detectMissingPlugs(plugsInUse = [], latestPlugs = []): IPlug[] {
  return plugsInUse.reduce((acc, plug) => {
    const plugExists = find(latestPlugs, { name: plug.name })

    return !plugExists ? acc.concat(plug) : acc
  }, [])
}

export function semverNewer(current: string, versions: string[]) {
  return versions?.filter(version => semver.gt(version, current))
}

export function bestSemverMatch(
  current: string,
  versions: string[],
): string | null {
  // Sort the versions array using semver.compare for correct numerical comparison
  const sortedVersions = sortBy(versions, version =>
    semver.valid(version) ? semver : semver.coerce(version),
  ).sort(semver.compare)

  // Filter for versions newer than the current one
  const newerVersions = sortedVersions.filter(version =>
    semver.gt(version, current),
  )

  // Find the best matching version that satisfies the current version's range
  const bestMatch = semver.maxSatisfying(newerVersions, `^${current}`)

  // Return the best match, or the latest version if no match is found
  return bestMatch || sortedVersions[sortedVersions.length - 1]
}

export function uniquePlugsFromNetwork(nodes = []) {
  // omit gates etc
  const plugs = nodes.reduce((acc, n) => {
    if (n.properties.sensor) {
      acc.push({
        ...n.properties.sensor,
        type: 'sensor',
      })
    }

    if (n.properties.actions) {
      acc = acc.concat(
        n.properties.actions.map(a => {
          return {
            ...pick(a, ['name', 'version']),
            type: 'actuator',
          }
        }),
      )
    }

    return acc
  }, [])

  // unique by name and version
  return uniqBy(plugs, (n: IPlug) => n.name + n.version)
}

export function convertPlugsV2EntityArrToPlugV0EntityArr(
  v2PlugsArr: any[],
): any[] {
  return v2PlugsArr.map(plugsV2Entity => {
    const pluginData = {
      type: plugsV2Entity.plug.type,
      name: plugsV2Entity.plug.name,
      version: plugsV2Entity.plug.version,
      author: plugsV2Entity.plug.metadata.author,
      description: plugsV2Entity.plug.metadata.description,
      mediaType: plugsV2Entity.plug.metadata.mediaType,
      iconURL: plugsV2Entity.plug.metadata.iconURL,
      category: plugsV2Entity.plug.metadata.category,
      configuration: plugsV2Entity.plug.configuration,
      isDeprecated: plugsV2Entity.plug.isDeprecated,
      tags: plugsV2Entity.plug.tags,
      states: plugsV2Entity.plug.interface.states,
      metadata: {
        tags: plugsV2Entity.plug.metadata.tags,
        states: plugsV2Entity.plug.metadata.states,
      },
      status: plugsV2Entity.plug.status,
    }
    return pluginData
  })
}
