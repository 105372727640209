import { useEffect, useState } from 'react'
import { cloneDeep, isEqual, isUndefined, omit, omitBy } from 'lodash-es'
import log from '~/lib/log'
import client from '~/lib/client'
import { usePrevious } from 'react-delta'
import { useMutation } from '@tanstack/react-query'

function useDeclarativeBinding(
  graph: any,
  getSerializedGraph: Function,
  focusedNodes: any,
  resource?: any,
) {
  const [currentSerializedGraph, setCurrentSerialzedGraph] = useState()
  const prevSerializedGraph = usePrevious(currentSerializedGraph)
  const [currentFocussedNode, setFocusedNode] = useState()
  const prevCurrentFocussedNode = usePrevious(currentFocussedNode)

  const fetchDataFn = async (arg: { serializedGraph: any }) => {
    const { serializedGraph } = arg
    const newGraph = cloneDeep(serializedGraph)
    newGraph.sensors = newGraph.sensors.map(sensor => omit(sensor, 'original'))
    newGraph.actuators = newGraph.actuators.map(actuator =>
      omit(actuator, 'original'),
    )
    return await client.templates.declarativeBindings(newGraph)
  }

  const {
    data: schema,
    isPending,
    mutate,
  } = useMutation({ mutationFn: fetchDataFn, onError, throwOnError: false })

  useEffect(() => {
    if (!graph.current) {
      return
    }

    try {
      setCurrentSerialzedGraph(getSerializedGraph())
      setFocusedNode(
        focusedNodes && focusedNodes.length === 1
          ? focusedNodes[0].label
          : undefined,
      )
    } catch (err) {
      // The editor currently has an invalid graph that can't be serialized.
      // Keep the old suggestions until graph is valid again.
    }
  }, [graph.current, focusedNodes])

  useEffect(() => {
    if (
      !graph.current ||
      (isEqual(currentSerializedGraph, prevSerializedGraph) &&
        isEqual(currentFocussedNode, prevCurrentFocussedNode))
    ) {
      return
    }

    try {
      const settings = omitBy(
        { selectedNode: currentFocussedNode, taskResource: resource },
        isUndefined,
      )
      const payload = {
        ...(currentSerializedGraph as Record<string, unknown>),
        settings,
      }
      mutate({ serializedGraph: payload })
    } catch (err) {
      onError(err)
    }
  }, [graph.current, currentSerializedGraph, currentFocussedNode])

  return { schema, isPending }
}

function onError(err: Error) {
  log.warn({ err }, 'Failed to load declarative binding suggestions, ignoring.')
}

export default useDeclarativeBinding
