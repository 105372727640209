import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { capitalize, get, isArray } from 'lodash-es'
import { Form, Message } from '@waylay/react-components'
import { useNavigate } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { useMutation } from '@tanstack/react-query'

import NewModal from '../../Common/NewModal'
import { verifyPlugV0Exists, verifyPlugV2Exists } from './usePlug'
import { useFlag } from '~/lib/flags'
import {
  getValidationSchema,
  useCreateFromExample,
} from '../Common/useCustomFunctions'
import { RuntimeContainer } from '../Common/useRuntimes'
import RuntimeSelection from '../Common/Tabs/Code/RuntimeSelection'
import { CustomFunctionsType, PlugType } from '../Common/Types'

interface INewModalProps {
  children: any
  type?: PlugType
}

const Decorator = styled.section`
  margin-top: 10px;
`

const initialValidationSchema = Yup.object().shape({
  name: Yup.string().required().max(100),
  type: Yup.string().oneOf(Object.values(PlugType)).required(),
})

const ModalView = ({ children, type = PlugType.Sensor }: INewModalProps) => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const edgeMode = useFlag('edgeMode', false)
  const { nonCallbackRuntimesMap } = RuntimeContainer.useContainer()
  const [validationSchema, setValidationSchema] = useState(
    initialValidationSchema,
  )

  const initialRuntime = useMemo(() => {
    if (isArray(nonCallbackRuntimesMap) && nonCallbackRuntimesMap.length > 0)
      return nonCallbackRuntimesMap[0]
    return { name: '', title: '', version: '' }
  }, [nonCallbackRuntimesMap])

  const { mutateAsync: createFromExample, isPending: createLoading } =
    useCreateFromExample()

  const createPlugin = useFormik({
    initialValues: {
      name: '',
      type,
      runtime: {
        name: initialRuntime.name,
        label: initialRuntime.title,
        version: initialRuntime.version,
      },
    },
    onSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema,
  })

  useEffect(() => {
    if (!edgeMode) {
      const getSchema = () => {
        getValidationSchema('node-legacy', '0.2.0', 'manifest')
          .then(res => {
            const nameValidations = res.properties.name
            const newValidationSchema = Yup.object().shape({
              name: Yup.string()
                .required()
                .max(nameValidations.maxLength)
                .matches(nameValidations.pattern, nameValidations.errorMessage),
              type: Yup.string().oneOf(Object.values(PlugType)).required(),
            })
            setValidationSchema(newValidationSchema)
          })
          .catch(() => {
            const newValidationSchema = Yup.object().shape({
              name: Yup.string()
                .required()
                .max(100)
                .matches(
                  /^[a-zA-Z0-9]+$/,
                  'can consist of up to 100 alphanumeric characters',
                ),
              type: Yup.string().oneOf(Object.values(PlugType)).required(),
            })
            setValidationSchema(newValidationSchema)
          })
      }
      getSchema()
    } else {
      const newValidationSchema = Yup.object().shape({
        name: Yup.string()
          .required()
          .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed'),
        type: Yup.string().oneOf(Object.values(PlugType)).required(),
      })
      setValidationSchema(newValidationSchema)
    }
  }, [edgeMode])

  const plugExists = useMutation({
    mutationFn: async (arg: { name: string; type: any }) => {
      const { name, type } = arg
      return edgeMode
        ? await verifyPlugV0Exists([name, type])
        : await verifyPlugV2Exists([name])
    },
    onSuccess: async nameExists => {
      if (nameExists) {
        createPlugin.setErrors({
          name: 'A plug with this name already exists',
        })
        return
      }
      const { type, name } = createPlugin.values
      edgeMode
        ? navigate(`/plugins/new?type=${type}&name=${name}`)
        : createFromExample({
            name,
            runtime: createPlugin.values.runtime.name,
            type: CustomFunctionsType.PLUGIN,
          })
            .then(createResponse => {
              const createEventLink = createResponse?._links?.event?.href
              navigate(
                `/plugins/sensors/${createResponse?.entity?.plug?.name}?version=${createResponse?.entity?.plug?.version}`,
                {
                  state: { createEventLink },
                },
              )
            })
            .catch(e => {
              const message =
                get(e, 'response.data.message') ?? get(e, 'response.data.error')
              addToast(`Something went wrong: ${message}`, {
                appearance: 'error',
              })
            })
    },
  })

  function onDismiss() {
    createPlugin.setErrors({})
    hideModal()
  }

  async function onSubmit({
    name,
    type,
  }: {
    name: string
    type: PlugType
    runtime: {
      label: string
      name: string
      version: string
    }
  }) {
    plugExists.mutate({ name, type })
  }
  const [showModal, hideModal] = useModal(
    () => (
      <NewModal
        title="Create new plug"
        submitText="Create plug"
        isSubmitting={plugExists.isPending || createLoading}
        onDismiss={onDismiss}
        onSubmit={createPlugin.handleSubmit}
      >
        {createPlugin.values.type !== 'sensors' && (
          <Message kind="warning" style={{ marginBottom: '10px' }}>
            {capitalize(createPlugin.values.type)} are deprecated and will be
            removed in a future release.{' '}
          </Message>
        )}

        <Form.Field>
          <label htmlFor="name">Name</label>
          <Form.Input.Group fluid>
            <Form.Input
              fluid
              autoFocus
              name="name"
              placeholder="name"
              id="name"
              defaultValue={createPlugin.values.name}
              onChange={createPlugin.handleChange}
            />
          </Form.Input.Group>
        </Form.Field>
        {!edgeMode && (
          <Form.Field>
            <Decorator>
              <RuntimeSelection
                isModal
                setRuntime={selection => {
                  createPlugin.setFieldValue('runtime', selection)
                  createPlugin.setFieldValue('type', 'sensors')
                }}
                labelVisibility
                isNew
                runtime={createPlugin.values.runtime}
                runtimeLabel={createPlugin.values.runtime.label}
              />
            </Decorator>
          </Form.Field>
        )}
        {Object.values(createPlugin.errors)[0] && (
          <Message kind="danger">
            {Object.values(createPlugin.errors)[0]}
          </Message>
        )}
      </NewModal>
    ),
    [createPlugin],
  )

  return children({ showModal })
}

export default ModalView
