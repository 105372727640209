import React from 'react'
import styled from '@emotion/styled'
import {
  Link,
  Routes,
  Route,
  useParams,
  useLocation,
  Outlet,
} from 'react-router-dom'
import {
  Button,
  Breadcrumb,
  Icon,
  concrete,
  Popup,
} from '@waylay/react-components'
import { Title } from '~/components/Common/Topbar'
import NewPluginModal from '~/components/PluginsAndWebscripts/Plugins/NewModal'
import createModal from '~/components/Resources/List/Add/NewResourceManagementModal'
import createConstraint from '~/components/ResourceConstraints/Detail/CreateConstraint'
import QueryStringProvider from '~/hooks/useQueryString'
import useTaskFormModal from '~/components/Tasks/Detail/FormModal/useTaskFormModal'
import PlugActionsList from '~/components/PluginsAndWebscripts/Plugins/PlugActionsList'
import LicenseAlarm from '~/components/Common/LicenseAlarm'
import { LicenseAlarmLocation } from '~/lib/types'
import { LicenseContainer } from '~/hooks/useLicense'
import { EType } from '~/components/Resources/Resources.types'

export const Container = styled.div`
  padding: 1em 2em;
  background: white;
  border-bottom: solid 1px ${concrete};
  display: flex;
  flex-direction: column;
`

export const Flexed = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  min-height: 32px;
`

export const FlexedRow = styled(Flexed)`
  flex-direction: column;
  align-items: start;
  justify-content: center;
  display: flex;
`

export const TextLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

export const safeDecodeURIComponent = (uriComponent: string) => {
  try {
    return decodeURIComponent(uriComponent)
  } catch (error) {
    return uriComponent
  }
}

export const safeEncodeURIComponent = (uriComponent: string) => {
  try {
    return encodeURIComponent(uriComponent)
  } catch (error) {
    return uriComponent
  }
}

const AlarmDetail = () => {
  const { id } = useParams<any>()

  return (
    <>
      <Container>
        <Flexed>
          <Breadcrumb
            sections={[
              {
                content: <TextLink to="/alarms">Alarms</TextLink>,
                key: 'alarms',
              },
              { content: id, key: 'id' },
            ]}
          />
        </Flexed>
      </Container>
      <Outlet />
    </>
  )
}

const IntegrationsDetail = () => (
  <>
    <Container>
      <FlexedRow>
        <Title>Data ingestion</Title>
      </FlexedRow>
    </Container>
    <LicenseAlarm
      location={LicenseAlarmLocation.SPECIFIC_TAB}
      customStyle={{ margin: '1.25em 1.25em 0.5em 1.25em' }}
    />
  </>
)

const AlarmTableView = () => {
  return (
    <Container>
      <FlexedRow>
        <Title>Alarms</Title>
      </FlexedRow>
    </Container>
  )
}

const IntegrationDetail = () => {
  const { pubsubName } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: (
                <TextLink to="/administration/settings/organisation/integrations">
                  Integrations
                </TextLink>
              ),
              key: 'integrations',
            },
            { content: pubsubName, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

export const PlugDetail = () => {
  const { type, name } = useParams<any>()

  return (
    <>
      <Container>
        <Flexed>
          <Breadcrumb
            sections={[
              {
                content: <TextLink to="/plugins">Plugins</TextLink>,
                key: type,
              },
              { content: name, key: 'name' },
            ]}
          />
        </Flexed>
      </Container>
      <LicenseAlarm
        location={LicenseAlarmLocation.SPECIFIC_TAB}
        customStyle={{ margin: '1.25em 1.25em 0.5em 1.25em' }}
      />
    </>
  )
}

export const PlugNew = () => {
  const { query } = QueryStringProvider.useContainer()
  const { type } = query

  return (
    <>
      <Container>
        <Flexed>
          <Breadcrumb
            sections={[
              {
                content: <TextLink to="/plugins">Plugins</TextLink>,
                key: type,
              },
              { content: 'New', key: 'name' },
            ]}
          />
        </Flexed>
      </Container>
      <LicenseAlarm
        location={LicenseAlarmLocation.SPECIFIC_TAB}
        customStyle={{ margin: '1.25em 1.25em 0.5em 1.25em' }}
      />
    </>
  )
}

const WebscriptNew = () => {
  const { query } = QueryStringProvider.useContainer()
  const { type } = query

  return (
    <>
      <Container>
        <Flexed>
          <Breadcrumb
            sections={[
              {
                content: <TextLink to="/webscripts">Webscripts</TextLink>,
                key: type,
              },
              { content: 'New', key: 'name' },
            ]}
          />
        </Flexed>
      </Container>
      <LicenseAlarm
        location={LicenseAlarmLocation.SPECIFIC_TAB}
        customStyle={{ margin: '1.25em 1.25em 0.5em 1.25em' }}
      />
    </>
  )
}

const WebscriptDetail = () => {
  const { name } = useParams<any>()

  return (
    <>
      <Container>
        <Flexed>
          <Breadcrumb
            sections={[
              {
                content: <TextLink to="/webscripts">Webscripts</TextLink>,
                key: 'webscripts',
              },
              { content: safeDecodeURIComponent(name), key: 'name' },
            ]}
          />
        </Flexed>
      </Container>
      <LicenseAlarm
        location={LicenseAlarmLocation.SPECIFIC_TAB}
        customStyle={{ margin: '1.25em 1.25em 0.5em 1.25em' }}
      />
    </>
  )
}

const WebscriptLogView = () => {
  const { name } = useParams<any>()

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const version = searchParams.get('version')
  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/webscripts">Webscripts</TextLink>,
              key: 'webscripts',
            },
            {
              content: (
                <TextLink to={`/webscripts/${name}?version=${version}`}>
                  {safeDecodeURIComponent(name)}
                </TextLink>
              ),
              key: 'name',
            },
            { content: 'Logs', key: 'logs' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

export const PlugList = () => {
  const { disabledActions } = LicenseContainer.useContainer()
  return (
    <>
      <Container>
        <Flexed>
          <FlexedRow>
            <Title>Plugins</Title>
          </FlexedRow>
          <Button.Group>
            <NewPluginModal>
              {({ showModal }) => (
                <Button onClick={showModal} disabled={disabledActions}>
                  <Icon name="add_circle_outline" /> Add plug
                </Button>
              )}
            </NewPluginModal>

            <Popup
              placement="bottom-end"
              appendTo={() => document.body}
              content={<PlugActionsList />}
            >
              <Button disabled={disabledActions}>
                <Icon name="keyboard_arrow_down" />
              </Button>
            </Popup>
          </Button.Group>
        </Flexed>
      </Container>
      <LicenseAlarm
        location={LicenseAlarmLocation.SPECIFIC_TAB}
        customStyle={{ margin: '1.25em 1.25em 0.5em 1.25em' }}
      />
    </>
  )
}

const ResourceList = () => {
  const { showModal } = createModal({
    type: EType.resource,
  })

  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Resources</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add resource
        </Button>
      </Flexed>
    </Container>
  )
}

const ResourceDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/resources">Resources</TextLink>,
              key: 'resources',
            },
            { content: decodeURIComponent(id), key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const ResourceTypes = () => {
  const { showModal } = createModal({
    type: EType.resourceType,
  })
  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Resource types</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add resource type
        </Button>
      </Flexed>
    </Container>
  )
}

const ResourceTypeDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/resourcetypes">Resource types</TextLink>,
              key: 'resourcetypes',
            },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const ResourceConstraints = () => {
  const { showModal } = createConstraint()
  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Constraints</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add constraint
        </Button>
      </Flexed>
    </Container>
  )
}

const ResourceConstraintDetail = () => {
  const { id } = useParams()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: (
                <TextLink to="/resourceconstraints">Constraints</TextLink>
              ),
              key: 'constraints',
            },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const TaskList = () => {
  const { showModal } = useTaskFormModal({})

  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Tasks</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add task
        </Button>
      </Flexed>
    </Container>
  )
}

const TaskDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            { content: <TextLink to="/tasks">Tasks</TextLink>, key: 'tasks' },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const SubflowDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            { content: 'Subflows', key: 'subflows' },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

export const TemplateDetail = () => {
  const { name } = useParams<any>()

  return (
    <>
      <Container>
        <Flexed>
          <Breadcrumb
            sections={[
              {
                content: (
                  <TextLink to="/templates?sort=updatetime:descending">
                    Templates
                  </TextLink>
                ),
                key: 'templates',
              },
              { content: name, key: 'name' },
            ]}
          />
        </Flexed>
      </Container>
      <LicenseAlarm
        location={LicenseAlarmLocation.SPECIFIC_TAB}
        customStyle={{ margin: '1.25em 1.25em 0.5em 1.25em' }}
      />
    </>
  )
}

const Settings = () => (
  <Container>
    <Flexed>
      <Title>Settings</Title>
    </Flexed>
  </Container>
)

const DataQueryList = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Data queries</Title>
      </FlexedRow>
      <Button to="/explore" as={Link}>
        <Icon name="add_circle_outline" /> Add data query
      </Button>
    </Flexed>
  </Container>
)

const ByomlList = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Machine learning</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Applications = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Applications</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Logs = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Logs</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Simulator = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Simulator</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Topbar = () => {
  return (
    <Routes>
      <Route path="/integrations/*" element={<IntegrationsDetail />} />
      <Route path="/alarms/:id/*" element={<AlarmDetail />} />
      <Route path="/alarms" element={<AlarmTableView />} />
      <Route
        path="/administration/settings/organisation/integrations/:pubsubName/:tab"
        element={<IntegrationDetail />}
      />
      <Route path="/webscripts/new" element={<WebscriptNew />} />
      <Route path="/webscripts/:name" element={<WebscriptDetail />} />
      <Route path="/webscripts/:name/logs" element={<WebscriptLogView />} />
      <Route path="/resources" element={<ResourceList />} />
      <Route path="/resources/:id/*" element={<ResourceDetail />} />
      <Route path="/resourcetypes" element={<ResourceTypes />} />
      <Route path="/resourcetypes/:id/*" element={<ResourceTypeDetail />} />
      <Route
        path="/resourceconstraints/:id/*"
        element={<ResourceConstraintDetail />}
      />
      <Route path="/resourceconstraints" element={<ResourceConstraints />} />
      <Route path="/tasks" element={<TaskList />} />
      <Route path="/tasks/:id/*" element={<TaskDetail />} />
      <Route path="/subflows/:id/*" element={<SubflowDetail />} />
      <Route path="/templates/:name" element={<TemplateDetail />} />
      <Route path="/administration/settings/*" element={<Settings />} />
      <Route path="/analytics/queries" element={<DataQueryList />} />
      <Route path="/analytics/byoml" element={<ByomlList />} />
      <Route path="/applications" element={<Applications />} />
      <Route path="logs/*" element={<Logs />} />
      <Route path="/simulator" element={<Simulator />} />
    </Routes>
  )
}

export default Topbar
