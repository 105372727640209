import React, { useEffect, useState } from 'react'
import { IPropertyInput } from '../../types'
import { FormGrid } from '~/components/PluginsAndWebscripts/Plugins/Details/Debug'
import PluginSelect from '~/components/Common/PluginSelect'
import VersionsSelect from '~/components/Common/VersionSelect'
import { cloneDeep, isEmpty } from 'lodash-es'
import { PropertyFormatType } from '~/lib/types'
import TemplateField from './TemplateField'
import DescriptorButton from '~/components/PluginsAndWebscripts/Plugins/Details/Descriptor/DescriptorButton'
import { descriptor } from '@waylay/genai-tools'
import client from '~/lib/client'
import { useToasts } from 'react-toast-notifications'
import { useMutation } from '@tanstack/react-query'
import * as yaml from 'js-yaml'
interface IPluginDescriptorProps {
  plugin: string
  version: string | null
}

interface ITemplateDescriptorProps {
  template: string
}

const Descriptor = ({
  property,
  setShouldShowDescriptor,
  setDescriptorName,
  setFieldValue,
  value,
}: IPropertyInput) => {
  const { name: descriptorName, format, mandatory } = property
  const { type } = format

  const { addToast } = useToasts()
  const [descriptorProperties, setDescriptorProperties] = useState<
    {} | IPluginDescriptorProps | ITemplateDescriptorProps
  >({})
  const [shouldNotClearValue, setShouldNotClearValue] = useState(false)

  const setPluginValue = (property: IPluginDescriptorProps) => {
    // TODO: check if plugin is different
    setShouldNotClearValue(true)
    setFieldValue(descriptorName, null)
    setDescriptorName(descriptorName)
    setDescriptorProperties(property)
  }

  useEffect(() => {
    let newDescriptorProperties = {}
    let jsonDescriptor = value

    if (typeof value === 'string') {
      try {
        jsonDescriptor = yaml.load(value)
      } catch {}
    }
    const formatType = property?.format?.type

    if (!shouldNotClearValue) {
      if (
        formatType === PropertyFormatType.AiPluginDescriptor ||
        formatType === PropertyFormatType.AiTemplateDescriptor
      ) {
        newDescriptorProperties =
          formatType === PropertyFormatType.AiPluginDescriptor
            ? {
                plugin: jsonDescriptor?.name ?? '',
                version: jsonDescriptor?.version ?? '',
              }
            : { template: jsonDescriptor?.name ?? '' }
        setDescriptorProperties(newDescriptorProperties)
      }
    } else {
      setShouldNotClearValue(false)
    }
  }, [value])

  const getDescriptor = useMutation({
    mutationFn: async (arg: {
      type: PropertyFormatType
      functionName: string
      version?: string
    }) => {
      const { type, version, functionName } = arg
      let descriptorSchema = null

      if (type === PropertyFormatType.AiPluginDescriptor) {
        descriptorSchema = await descriptor.plugin(
          client,
          functionName,
          version,
        )
      } else {
        descriptorSchema = await descriptor.template(client, functionName)
      }
      return descriptorSchema
    },
    onSuccess: generatedDescriptor => {
      setFieldValue(descriptorName, generatedDescriptor)
      setShouldShowDescriptor(true)
    },
    throwOnError: false,
    onError: err => {
      setFieldValue(descriptorName, null)
      addToast(err?.message, { appearance: 'error' })
    },
  })

  const setVersion = (version: string) => {
    setDescriptorName(descriptorName)
    const newProp = cloneDeep(descriptorProperties as IPluginDescriptorProps)
    newProp.version = version
    setDescriptorProperties(newProp)
    getDescriptor.mutate({
      type: PropertyFormatType.AiPluginDescriptor,
      functionName: (descriptorProperties as IPluginDescriptorProps).plugin,
      version,
    })
  }

  const setTemplate = (_propName: any, value: string) => {
    setDescriptorName(descriptorName)
    setDescriptorProperties({ template: value })
    if (!isEmpty(value)) {
      getDescriptor.mutate({
        type: PropertyFormatType.AiTemplateDescriptor,
        functionName: value,
      })
    } else {
      setFieldValue(descriptorName, null)
    }
  }

  return (
    <div
      style={{
        marginBottom: '5px',
        gridColumn: 'span 2',
      }}
    >
      {type === PropertyFormatType.AiPluginDescriptor && (
        <>
          <LabelAndInput
            text="Plugin"
            customStyle={{ marginBottom: '5px' }}
            testid="plugin"
          >
            <PluginSelect
              value={(descriptorProperties as IPluginDescriptorProps)?.plugin}
              setFieldValue={setPluginValue}
            />
          </LabelAndInput>
          <LabelAndInput text="Version" testid="version">
            <VersionsSelect
              value={(descriptorProperties as IPluginDescriptorProps)?.version}
              setFieldValue={setVersion}
              pluginValue={
                (descriptorProperties as IPluginDescriptorProps)?.plugin
              }
            />
          </LabelAndInput>
        </>
      )}
      {type === PropertyFormatType.AiTemplateDescriptor && (
        <LabelAndInput text="Template" testid="template">
          <TemplateField
            value={(descriptorProperties as ITemplateDescriptorProps)?.template}
            property={property}
            setFieldValue={setTemplate}
          />
        </LabelAndInput>
      )}
      <div style={{ marginTop: '5px', display: 'flex', flexDirection: 'row' }}>
        <label
          style={{ fontSize: '0.9em' }}
          htmlFor={descriptorName}
          data-testid="descriptor-label"
        >
          {descriptorName}
        </label>
        {mandatory && (
          <label
            data-testid="mandatory-label"
            css={{
              marginRight: 'auto',
              color: '#B00020 !important',
              fontSize: '0.9em',
            }}
          >
            *
          </label>
        )}
        <DescriptorButton
          value={value}
          size="small"
          setDescriptor={() => {
            setDescriptorName(descriptorName)
            setShouldShowDescriptor(true)
          }}
        />
      </div>
    </div>
  )
}

const LabelAndInput = ({
  children,
  text,
  customStyle,
  testid,
}: {
  children: React.ReactNode
  text: string
  customStyle?: any
  testid?: string
}) => {
  return (
    <FormGrid style={{ ...customStyle }}>
      <label htmlFor={text} data-testid={`label-${testid}`}>
        {text}
      </label>
      {children}
    </FormGrid>
  )
}
export default Descriptor
