export const INTEGRATIONS_KEY = 'Integrations'
export const CONSTRAINTS_KEY = '$constraints'
export const TEMPLATES_KEY = 'templates'

// DATA INGESTION
export const DATA_INGESTION_INTEGRATIONS_KEY = 'Data-ingestion-integrations-key'
export const DATA_INGESTION_TEMPLATES_KEY = 'Data-ingestion-templates-key'
export const DATA_INGESTION_CONFIG_KEY = 'Data-ingestion-config-key'
export const DATA_INGESTION_STATUS_KEY = 'Data-ingestion-status-key'
export const DATA_INGESTION_UPDATE_CONFIG_KEY =
  'Data-ingestion-update-config-key'
export const DATA_INGESTION_CONNECT_KEY = 'Data-ingestion-connect-key'
export const DATA_INGESTION_DISCONNECT_KEY = 'Data-ingestion-disconnect-key'
export const DATA_INGESTION_START_KEY = 'Data-ingestion-start-key'
export const DATA_INGESTION_STOP_KEY = 'Data-ingestion-stop-key'

// LANDING PAGE
export const LANDING_PAGE_TEMPLATES_KEY = 'Landing-page-templates-key'
export const LANDING_PAGE_WEBSCRIPTS_KEY = 'Landing-page-webscripts-key'
export const LANDING_PAGE_RESOUCES_KEY = 'Landing-page-resources-key'
export const LANDING_PAGE_ALARMS_KEY = 'Landing-page-alarms-key'
export const LANDING_PAGE_TASKS_KEY = 'Landing-page-tasks-key'
export const LANDING_PAGE_DISCOVER_KEY = 'Landing-page-discover-key'

// ALARMS
export const ALARMS_LIST_KEY = 'Alarms-list-key'
export const ALARMS_DETAILS_KEY = 'Alarms-details-key'
export const ALARM_DETAILS_RESOLVED_KEY = 'Alarm-details-resolved-key'
export const ALARM_DETAILS_REMOVE_KEY = 'Alarm-details-remove-key'
export const ALARM_DETAILS_UNACKNOWLEDGE_KEY = 'Alarm-details-unacknowledge-key'
export const ALARM_DETAILS_ACKNOWLEDGE_KEY = 'Alarm-details-acknowledge-key'

// PROFILE
export const PROFILE_KEY = 'Profile'

// SETTINGS
export const PASSWORD_RESET_KEY = 'Password-reset-key'
export const TWO_FACTOR_AUTHENTICATION_KEY = 'Two-factor-authentication-key'
export const CREATE_API_KEY = 'Create-api-key'
export const REMOVE_API_KEY = 'Remove-api-key'
export const GENERAL_SETTINGS_KEY = 'General-settings-key'
export const ADD_GENERAL_SETTINGS_KEY = 'Add-general-settings-key'
export const REMOVE_GENERAL_SETTINGS = 'Remove-general-settings'
export const VAULT_SETTINGS_KEY = 'Vault-settings-key'
export const ADD_VAULT_KEY = 'Add-vault-key'
export const REMOVE_VAULT_KEY = 'Remove-vault-key'
export const REVEAL_VAULT_KEY = 'Reveal-vault-key'
export const CONNECTIONS_LIST_KEY = 'Connections-list-key'
export const REFRESH_PROVIDERS_KEY = 'Refresh-providers-key'
export const USERS_KEY = 'Users-key'
export const TWO_FACTOR_CONFIG_KEY = 'Two-factor-config-key'
export const UPDATE_ENFORCEMENT_KEY = 'Update-enforcement-key'
export const ADD_USER_KEY = 'Add-user-key'
export const REMOVE_USER_KEY = 'Remove-user-key'
export const USER_DETAILS_KEY = 'User-details-key'
export const ADD_USER_ROLE_KEY = 'Add-user-role-key'
export const REMOVE_USER_ROLE_KEY = 'Remove-user-role-key'
export const RESET_USER_PASSWORD_KEY = 'Reset-user-password-key'
export const ROLES_KEY = 'Roles-key'
export const PREDEFINED_ROLES_KEY = 'Predefined-roles-key'
export const ADD_ROLE_KEY = 'Add-role-key'
export const REMOVE_ROLE_KEY = 'Remove-role-key'
export const ROLE_KEY = 'Role-key'
export const ADD_ROLE_PERMISSION_KEY = 'Add-role-permission-key'
export const REMOVE_ROLE_PERMISSION_KEY = 'Remove-role-permission-key'
export const EXPORT_DETAILS_KEY = 'Export-details-key'
export const CREATE_SCHEDULE_KEY = 'Create-schedule-key'
export const REMOVE_SCHEDULE_KEY = 'Remove-schedule-key'
export const LICENSE_KEY = 'License-key'
export const USAGE_KEY = 'Usage-key'

// STORAGE
export const DOWNLOAD_BUCKET_KEY = 'Download-bucket-key'
export const REMOVE_FILE_KEY = 'Remove-file-key'
export const ADD_FOLDER_KEY = 'Add-folder-key'
export const REMOVE_FOLDER_KEY = 'Remove-folder-key'
export const UPLOAD_FILE_KEY = 'Upload-file-key'
export const SUBSCRIPTIONS_KEY = 'subscriptions-key'
export const REMOVE_SUBSCRIPTION_KEY = 'remove-subscription-key'
export const ADD_SUBSCRIPTION_KEY = 'add-subscription-key'
export const SUBSCRIPTION_DETAILS_KEY = 'subscription-details-key'
export const EDIT_FILTER_KEY = 'edit-filter-key'
export const REMOVE_FILTER_KEY = 'remove-filter-key'

// RESOURCES
export const RESOURCE_PARENT_KEY = 'resource-parent'
export const RESOURCE_CHILDREN_KEY = 'resource-children'
export const RESOURCE_REFERRERS_KEY = 'resource-referrers'
export const RESOURCES_HISTORY_KEY = 'history-resources'
export const RESOURCE_DETAIL_KEY = 'resource-details'
export const RESOURCES_LIST_KEY = 'resources-list'
export const CREATE_RESOURCE_KEY = 'create-resource'
export const RESOURCE_METRIC_KEY = 'resource-metric'
export const RESOURCE_SELECT_NO_FILTER_KEY = 'resource-select-no-filter'

// RESOURCE TYPES
export const RESOURCETYPE_KEY = 'resourcetype'
export const RESOURCETYPE_HISTORY_KEY = 'history-resourcetype'

// CONSTRAINTS
export const CONSTRAINT_KEY = 'resourceconstraint'
export const CREATE_CONSTRAINT_KEY = 'create-constraint'

// RESOURCE METRICS
export const RESOURCE_METRICS_KEY = 'resource-metrics'
export const RESOURCE_METRIC_HISTORY_KEY = 'resource-metric-history'

// DATA QUERIES
export const DATA_QUERIES_LIST_KEY = 'data-queries-list'

// TASKS
export const TASK_DETAIL_KEY = 'task-detail'
export const TASK_EDITOR_KEY = 'task editor'

// NLP
export const NLP_LIST_KEY = 'nlp-list-key'

// PLUGS
export const PLUG_VERSIONS_KEY = 'versions-key'
export const EXAMPLE_FUNCTION_KEY = 'example-function-key'
