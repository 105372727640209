import React, { ReactNode } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  Alert,
  Controls,
  FormEdition,
  TextOptions,
} from '@streamlinehq/streamline-bold/lib/interface-essential'
import {
  Analytics,
  SEO,
} from '@streamlinehq/streamline-bold/lib/business-products'
import {
  BugSecurity,
  ProgramingLanguages,
  PluginsModules,
} from '@streamlinehq/streamline-bold/lib/programing-apps-websites'
import { Compass } from '@streamlinehq/streamline-bold/lib/maps-navigation'
import { Shapes } from '@streamlinehq/streamline-bold/lib/design'
import { PowerSupply } from '@streamlinehq/streamline-bold/lib/computers-devices-electronics'
import {
  Servers,
  DataTransfer,
} from '@streamlinehq/streamline-bold/lib/internet-networks-servers'
import { ArtificialIntelligence } from '@streamlinehq/streamline-bold/lib/technology'
import { LabsExperiments } from '@streamlinehq/streamline-bold/lib/science'

import {
  Icon,
  Menu,
  Tooltip,
  StreamlineIcon,
  concrete,
  colors,
  carbon,
} from '@waylay/react-components'
import { Link, NavLink } from 'react-router-dom'

import Branding from '~/components/App/Branding'
import useCollapsed from './useCollapsed'
import Profile from './Profile'
import { useFlag } from '~/lib/flags'
import { omit, pick } from 'lodash-es'

const LogoLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  margin: 0.5em 0;
`

const Logo = styled.img`
  max-height: 35px;
  max-width: 120px;
`

const NavText = styled.span`
  margin-left: 0.65em;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CenteredItem = css`
  height: 35px;
  line-height: 2.5rem;
  display: flex;
  align-items: center;
`

const FlexMenu = styled(Menu)`
  display: flex;
  color: ${carbon};
  flex-direction: column;
  height: 100%;

  flex-shrink: 0;

  &[aria-expanded='false'] {
    ${NavText} {
      display: none;
    }
    ${Menu.Item} {
      width: 35px;
    }
    ${Logo} {
      width: 35px;
      height: 35px;
      padding: 0px;
    }
  }
`

const NavigationContent = styled.div`
  padding: 1em;
  flex: 1 1;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 5px inset;

  ${Menu.Item}, [aria-expanded] {
    ${CenteredItem};
    padding: 0 5px;

    &.active > i {
      color: white;
    }
  }
`

// keep at 21px to be crisp
const iconStyle = css`
  font-size: 21px;
  color: ${colors.withWeight(carbon, 400)};
`

const NavIcon = styled(Icon)(iconStyle)

const NavStreamlineIcon = styled(StreamlineIcon)(iconStyle)

interface INavigationItem {
  name: string
  icon: string | ReactNode
  collapsed: boolean
  exact?: boolean
  path?: string
  flag?: string
}

const NavigationItem = (props: INavigationItem) => {
  const flag = props.flag ? useFlag(props.flag, true) ?? true : true

  return flag ? (
    <NavItem {...omit(props, ['flag'])} />
  ) : (
    <DisabledNavItem {...pick(props, ['name', 'icon'])} />
  )
}

interface INavItem {
  name: string
  icon: string | ReactNode
  collapsed: boolean
  path?: string
}

const NavItem = ({ name, icon, collapsed, path }: INavItem) => {
  return (
    <Tooltip
      placement="right"
      content={<span>{name}</span>}
      enabled={collapsed}
    >
      <div>
        <Menu.Item as={NavLink} to={path}>
          {icon}
          <NavText>{name}</NavText>
        </Menu.Item>
      </div>
    </Tooltip>
  )
}

interface IDisabledNavItemProps {
  name: string
  icon: string | ReactNode
}

const DisabledNavItem = ({ name, icon }: IDisabledNavItemProps) => {
  return (
    <Tooltip
      placement="right"
      content={
        <span>{name} · This feature is not available in your installation</span>
      }
      enabled
    >
      <div css={DisabledStyles}>
        <Menu.Item>
          {icon}
          <NavText>{name}</NavText>
        </Menu.Item>
      </div>
    </Tooltip>
  )
}

const DisabledStyles = css`
  color: ${colors.withWeight(carbon, 200)};
  background-color: ${colors.withWeight(concrete, 400)};
  border-radius: 2px;

  div:hover {
    cursor: default;
    background-color: ${colors.withWeight(concrete, 400)};
  }

  i {
    color: ${colors.withWeight(carbon, 200)};
  }
`

const NavigationActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Collapse = styled.button`
  background: transparent;
  border: 0;
  text-align: left;
  padding: 1em 1.5em;
  width: 100%;
  border-top: solid 1px ${concrete};
  user-select: none;
  cursor: pointer;

  &:hover {
    background: #fff;
  }
`

export default function SideBar() {
  const { collapsed, toggleCollapsed } = useCollapsed.useContainer()
  const { logo: wideLogo, squareLogo } = Branding.useContainer()

  const logoSrc = collapsed ? squareLogo : wideLogo
  const collapseIcon = collapsed ? 'last_page' : 'first_page'

  return (
    <FlexMenu role="menu" aria-expanded={!collapsed}>
      <NavigationContent>
        <LogoLink to="/">
          <Logo src={logoSrc} role="logo" />
        </LogoLink>
        <Menu.List>
          <NavigationItem
            name="Alarms"
            icon={<NavStreamlineIcon iconData={Alert.AlarmBell} />}
            path="/alarms?query=status:active&sort=lastTriggeredTime:descending"
            collapsed={collapsed}
            flag="alarms"
          />
          <NavigationItem
            name="Data ingestion"
            icon={
              <NavStreamlineIcon
                iconData={DataTransfer.DataTransferHorizontal}
              />
            }
            path="/integrations"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Explore"
            icon={<NavStreamlineIcon iconData={Compass.Compass1} />}
            path="/explore"
            collapsed={collapsed}
            flag="explore"
          />
          <NavigationItem
            name="Resources"
            icon={<NavStreamlineIcon iconData={PluginsModules.ModuleFour} />}
            path="/resources"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Resource types"
            icon={<NavStreamlineIcon iconData={Shapes.Shapes} />}
            path="/resourcetypes"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Constraints"
            icon={<NavStreamlineIcon iconData={BugSecurity.ShieldCheck} />}
            path="/resourceconstraints"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Plugins"
            icon={<NavStreamlineIcon iconData={PowerSupply.Charger1} />}
            path="/plugins"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Tasks"
            icon={<NavStreamlineIcon iconData={FormEdition.TaskChecklist} />}
            path="/tasks?query=status:running&sort=creationtime:descending"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Templates"
            icon={<NavStreamlineIcon iconData={TextOptions.TaskText} />}
            path="/templates?sort=updatetime:descending"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Webscripts"
            icon={
              <NavStreamlineIcon
                iconData={ProgramingLanguages.ProgrammingLanguageHttp}
              />
            }
            path="/webscripts"
            collapsed={collapsed}
            flag="webscripts"
          />

          <NavigationItem
            name="Storage"
            icon={<NavStreamlineIcon iconData={Servers.Server3} />}
            path="/storage/buckets"
            collapsed={collapsed}
            flag="storage"
          />

          <NavigationItem
            name="Data queries"
            icon={<NavStreamlineIcon iconData={Analytics.AnalyticsMountain} />}
            path="/analytics/queries"
            collapsed={collapsed}
            flag="queries"
          />
          <NavigationItem
            name="Machine learning"
            icon={<NavStreamlineIcon iconData={ArtificialIntelligence.Brain} />}
            path="/analytics/byoml"
            collapsed={collapsed}
            flag="byoml"
          />
          <NavigationItem
            name="Applications"
            icon={<NavStreamlineIcon iconData={PluginsModules.ModuleThree1} />}
            path="/applications"
            collapsed={collapsed}
            flag="applications"
          />

          <NavigationItem
            name="Logs"
            icon={<NavStreamlineIcon iconData={SEO.SeoSearch} />}
            path="/logs"
            collapsed={collapsed}
          />
          <NavigationItem
            name="Simulator"
            icon={
              <NavStreamlineIcon
                iconData={LabsExperiments.LabFlaskExperiment}
              />
            }
            path="/simulator"
            collapsed={collapsed}
          />
          <NavigationItem
            name="NLP"
            icon={
              <NavStreamlineIcon
                iconData={ProgramingLanguages.ProgrammingLanguageCode}
              />
            }
            path="/nlp/rulebase"
            collapsed={collapsed}
            flag="nlp"
          />
          <NavigationItem
            name="Settings"
            icon={
              <NavStreamlineIcon iconData={Controls.SettingsSliderAlternate} />
            }
            path="/administration/settings"
            collapsed={collapsed}
          />
        </Menu.List>
      </NavigationContent>

      <NavigationActions>
        <Profile collapsed={collapsed} />
        <Collapse
          data-testid="collapse-button"
          aria-expanded={!collapsed}
          onClick={toggleCollapsed}
          css={css`
            ${collapsed && 'text-align: right;'}
          `}
        >
          <NavIcon name={collapseIcon} />
          <NavText>Collapse</NavText>
        </Collapse>
      </NavigationActions>
    </FlexMenu>
  )
}
