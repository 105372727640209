import { useState } from 'react'
import { createContainer } from 'unstated-next'
import { cloneDeep, isEmpty } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import { IChart, IQueryConfig, ISerie } from './DataQueriesTypes'

interface IDefaultChart {
  name: string
  id: string
  series: string[]
}

const useCharts = (initialState: IChart[] = []) => {
  const [charts, setCharts] = useState<IChart[]>(initialState)

  const createChartsForPreviousVersion = (config: IQueryConfig) => {
    const charts = []
    config.series.forEach(series => {
      charts.push({
        id: uuidv4(),
        name: '<no_name>',
        series: [series],
      })
    })
    setCharts(charts)
  }
  // Manipulate chart
  const transformChart = (config: IQueryConfig, charts: IDefaultChart[]) => {
    setCharts(
      charts.map(chart => ({
        name: chart.name,
        id: chart.id,
        series: config.series.filter(serieConfig =>
          chart.series.includes(serieConfig.seriesId),
        ),
      })),
    )
  }

  const addChart = () => {
    setCharts(current => [
      ...current,
      {
        name: '<no_name>',
        id: uuidv4(),
        series: [],
      },
    ])
  }

  const removeChart = (id: string) => {
    const chartsCopy = cloneDeep(charts)
    const chartIndex: number = chartsCopy.findIndex(
      (chart: IChart) => chart.id === id,
    )
    chartsCopy.splice(chartIndex, 1)
    setCharts(chartsCopy)
  }

  const changeChartName = (name: string, id: string) => {
    const chartsCopy = cloneDeep(charts)
    const chartFound = chartsCopy.find((chart: IChart) => chart.id === id)
    if (chartFound) {
      chartFound.name = name
    }
    setCharts(chartsCopy)
  }

  // Manipulate series for charts
  const addSerieToChart = (id: string, serie: ISerie) => {
    const chartsCopy = cloneDeep(charts)
    const chartFound: IChart = chartsCopy.find(
      (chart: IChart) => chart.id === id,
    )
    const serieFound: ISerie = chartFound.series.find(
      (chartSerie: ISerie) => chartSerie.seriesId === serie.seriesId,
    )
    if (!serieFound) {
      chartFound.series.push(serie)
      setCharts(chartsCopy)
    }
  }

  const removeSerieFromChart = (chartId: string, serieId: string) => {
    const chartsCopy = cloneDeep(charts)
    const chartFound: IChart = chartsCopy.find(
      (graphElement: IChart) => graphElement.id === chartId,
    )
    const chartFoundIndex: number = chartsCopy.findIndex(
      (graphElement: IChart) => graphElement.id === chartId,
    )
    chartFound.series.forEach((series, index) => {
      if (series.seriesId === serieId) {
        chartsCopy[chartFoundIndex].series.splice(index, 1)
      }
    })
    setCharts(chartsCopy)
  }

  const updateSerieForAllCharts = (
    serieId: string,
    newConfig: IQueryConfig,
  ) => {
    const chartsCopy = cloneDeep(charts)
    if (!isEmpty(chartsCopy)) {
      chartsCopy.forEach((chart: IChart, chartIndex) => {
        if (!isEmpty(chart.series)) {
          chart.series.forEach((series, index) => {
            if (series.seriesId === serieId) {
              const serie = {
                ...chartsCopy[chartIndex].series[index],
                ...newConfig,
              }
              chartsCopy[chartIndex].series[index] = serie
            }
          })
        }
      })
      setCharts(chartsCopy)
    }
  }

  const removeSerieFromAllCharts = (serieId: string) => {
    const chartsCopy = cloneDeep(charts)
    charts.forEach((chart: IChart, chartIndex) => {
      chart.series.forEach((serie, serieIndex) => {
        if (serie.seriesId === serieId) {
          chartsCopy[chartIndex].series.splice(serieIndex, 1)
        }
      })
    })
    setCharts(chartsCopy)
  }

  return {
    charts,
    setCharts,
    transformChart,
    addChart,
    removeSerieFromChart,
    updateSerieForAllCharts,
    removeSerieFromAllCharts,
    removeChart,
    changeChartName,
    addSerieToChart,
    createChartsForPreviousVersion,
  }
}

export default useCharts
export const ChartContainer = createContainer(useCharts)
