import React, { Fragment, useState, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AsyncSelect, watermelon } from '@waylay/react-components'
import { get, isEmpty, noop } from 'lodash-es'

import { fetchResources } from '../../hooks/useResourcesList'
import { RESOURCE_SELECT_NO_FILTER_KEY } from '~/lib/QueryKeys'

const EMPTY_FILTER = ''

export const findResources = async (filter: string) => {
  const resources = await fetchResources({ filter, limit: 30 })

  return get(resources, '_embedded.values', []).map(resource => ({
    ...resource,
    value: resource.id,
  }))
}

const HighlightFilter = ({ result = '', inputValue }) => (
  <div>
    {result.split(inputValue).map((p, i) =>
      i ? (
        <Fragment key={i}>
          <strong style={{ textDecoration: 'underline' }}>{inputValue}</strong>
          {p}
        </Fragment>
      ) : (
        <Fragment key={i}>{p}</Fragment>
      ),
    )}
  </div>
)

const MenuOption = ({ name, id, inputValue }) => (
  <>
    <HighlightFilter {...{ result: name, inputValue }} />
    <small>
      <HighlightFilter {...{ result: id, inputValue }} />
    </small>
  </>
)

const formatOptionLabel = ({ name, id, value }, { context, inputValue }) =>
  context === 'value' ? (
    <>{value}</>
  ) : (
    <MenuOption {...{ name, id, inputValue }} />
  )

const noOptionsMessage = ({ inputValue }) =>
  inputValue.length
    ? `No resources found containing '${inputValue}'`
    : 'Start typing…'

const ResourceSelect = (props: IResourceSelect) => {
  const {
    resource,
    onChange,
    allowAnyInput = false,
    resetValueOnBlur = true,
    inputId = 'resource',
    isError = false,
    ...rest
  } = props

  // Keep input after blur event even if the option was not in the search results.
  const [inputValue, setInputValue] = useState()
  const handleInputChange = useCallback(
    (inputValue, { action }) => {
      if (action !== 'input-blur' && action !== 'menu-close') {
        setInputValue(inputValue)
        onChange({ value: inputValue })
      }
    },
    [setInputValue, onChange],
  )

  // when dropdown is not manually clicked then it does not scroll into view
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        'ResourcesDropdown__option--is-selected',
      )[0]
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'start',
        })
      }
    }, 15)
  }

  const { data: defaultOptions = [] } = useQuery({
    queryKey: [RESOURCE_SELECT_NO_FILTER_KEY],
    queryFn: () => findResources(EMPTY_FILTER),
  })

  const loadOptions = useCallback(
    async filter => {
      if (!filter) {
        return defaultOptions
      }

      const resources = await findResources(filter)
      return isEmpty(resources) && allowAnyInput
        ? [{ name: filter, value: filter }]
        : resources
    },
    [allowAnyInput, defaultOptions],
  )

  const selectProps = {
    inputId,
    'data-testid': 'resource-select',
    cacheOptions: true,
    defaultValue: resource ? { label: resource, value: resource } : undefined,
    loadOptions,
    onChange: v => onChange(v || {}),
    isClearable: true,
    formatOptionLabel,
    noOptionsMessage,
    onMenuOpen,
    className: 'ResourcesDropdown',
    classNamePrefix: 'ResourcesDropdown',
    inputValue,
    onInputChange: resetValueOnBlur ? noop : handleInputChange,
    defaultOptions,
    ...rest,
  }
  return (
    <AsyncSelect
      {...selectProps}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          borderColor: isError ? watermelon : '#e6e6e6',
          '&:hover': {
            borderColor: isError ? watermelon : '#b3b3b3',
          },
        }),
      }}
    />
  )
}

interface IResourceSelect extends Omit<React.HTMLAttributes<any>, 'onChange'> {
  name?: string
  resource?: string
  loadOptions?: Function
  allowAnyInput?: boolean
  resetValueOnBlur?: boolean
  inputId?: string
  filterOption?: Function
  placeholder?: string
  isError?: boolean
  // TODO: don't shadow existing DOM onChange definition
  onChange: Function
}

export default ResourceSelect
