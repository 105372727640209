import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createContainer } from 'unstated-next'
import { useToasts } from 'react-toast-notifications'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { cloneDeep, get, isArray, isEmpty } from 'lodash-es'
import { ResponseType } from '@waylay/client/dist/resource'

import client from '../../../lib/client'
import { WebscriptManifest, WebscriptState } from '~/lib/types'
import ProfileContainer, {
  useProfile,
} from '../../Dashboard/Sidebar/useProfile'
import {
  CustomFunctionsType,
  DeploymentStatus,
  JobEventType,
  JobType,
  PlugV2Structure,
} from './Types'
import { createFileDownload, REVISION_ERROR_PATTERN } from '~/lib/util'
import { EXAMPLE_FUNCTION_KEY } from '~/lib/QueryKeys'
import { useMutateQueryPartialKey } from '~/components/Common/QueryHelpers'
import { RuntimeContainer } from './useRuntimes'
import { useLogin } from '~/components/App/LoginContext'
import { PLUGIN_KEY } from '../Plugins/usePlug'
import { WEBSCRIPTS_KEY } from '../Webscripts/useWebscripts'
import {
  DEFAULT_VERSION,
  MIME_TYPE,
  CONTENT_RESPONSE_TYPE,
  FUNCTION_RUNTIME,
  FUNCTION_TAGS_RENDERING,
} from '~/lib/Constants'

export const FUNCTION_KEY = 'Function'
export const PLUGIN_VERSIONS = 'PLUGINS_VERSIONS'

export const useCustomFunction = () => {
  const { name } = useParams()
  const { allRuntimes, allRuntimesIsFulfilled } =
    RuntimeContainer.useContainer()

  const [isSaveEventPending, setIsSaveEventPending] = useState(false)

  const { search, pathname } = useLocation()
  const searchParams = new URLSearchParams(search)
  const version = searchParams.get('version')

  const { addToast } = useToasts()
  const { hasPermissionToViewProtectedContent } = useLogin()

  const type =
    pathname.split('/')[1] === 'plugins'
      ? CustomFunctionsType.PLUGIN
      : CustomFunctionsType.WEBSCRIPT

  const queryKey = [FUNCTION_KEY, name, version, allRuntimesIsFulfilled]

  const {
    data,
    error,
    isLoading,
  }: { data: any; error: any; isLoading: boolean } = useQuery({
    queryKey,
    queryFn: async () => {
      if (allRuntimesIsFulfilled) {
        const customFunction = await fetchCustomFunction(name, version, type)

        let runtime =
          allRuntimes &&
          Object.values(allRuntimes)?.find(
            runtime =>
              runtime.name === customFunction.entity.runtime.name &&
              runtime.version === customFunction.entity.runtime.version,
          )
        if (runtime === undefined) {
          const newRuntime = await client.registry.runtimes.get(
            customFunction.entity.runtime.name,
            customFunction.entity.runtime.version,
          )
          runtime = newRuntime.runtime
        }

        const isProtected = customFunction.entity[type].protected
        const isRestrictedAccessToContent =
          !hasPermissionToViewProtectedContent && isProtected

        if (isRestrictedAccessToContent) {
          return {
            details: { ...customFunction.entity, isRestrictedAccessToContent },
          }
        } else {
          const details = {
            ...customFunction.entity,
            link: customFunction?._links?.invoke?.href,
          }
          const currentVersion = version ?? customFunction.entity[type].version

          const scriptNames = await getScriptNames(
            customFunction.entity[type].name,
            customFunction.entity[type].version,
            false,
            type,
          )

          const [script, project, manifest, assets] = await Promise.all([
            fetchFile(
              name,
              currentVersion,
              scriptNames.mainScriptName,
              CONTENT_RESPONSE_TYPE.JSON,
              type,
            ),
            fetchFile(
              name,
              currentVersion,
              scriptNames.projectScriptName,
              CONTENT_RESPONSE_TYPE.JSON,
              type,
            ),
            fetchFile(
              name,
              currentVersion,
              scriptNames.manifestName,
              CONTENT_RESPONSE_TYPE.JSON,
              type,
            ),
            fetchAdditionalAssets(name, currentVersion, type),
          ])

          let dependencies = {}
          try {
            dependencies = formatDependencies(project, runtime.archiveFormat)
          } catch {
            addToast('Failed to parse dependencies', { appearance: 'error' })
          }

          return {
            runtime,
            details,
            manifest,
            script,
            dependencies,
            assets: { ...assets },
          }
        }
      }
      // IMPORTANT: This is a hack as we need a pending state for the editor until the function is loaded
      // eslint-disable-next-line @typescript-eslint/return-await
      return new Promise(() => {})
    },
    refetchOnWindowFocus: false,
    gcTime: 0,
  })

  return {
    loading: isLoading,
    error,
    data,
    isSaveEventPending,
    setIsSaveEventPending,
  }
}

export const CustomFunctionContainer = createContainer(useCustomFunction)

export const fetchFile = async (
  name: string,
  version = DEFAULT_VERSION,
  fileName: string,
  responseType: ResponseType = CONTENT_RESPONSE_TYPE.JSON,
  type = CustomFunctionsType.WEBSCRIPT,
): Promise<string | JSON | Blob> => {
  return await ((await getFunctionClient(type).getFileContent(
    name,
    version,
    fileName,
    {},
    { responseType },
  )) as Promise<string | JSON>)
}

export const verifyWebscriptExists = async (name: string): Promise<boolean> => {
  return await client.registry.webscripts.exists(name)
}

export const useExampleFunction = (type: CustomFunctionsType) => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(decodeURI(search))
  const name = searchParams.get('name')
  const runtimeName = searchParams.get('runtimeName')
  const runtimeVersion = searchParams.get('runtimeVersion')
  const profile = ProfileContainer.useContainer()
  const { allRuntimes, allRuntimesIsFulfilled } =
    RuntimeContainer.useContainer()

  const { addToast } = useToasts()

  const example = useQuery({
    queryKey: [EXAMPLE_FUNCTION_KEY, allRuntimesIsFulfilled],
    queryFn: async () => {
      if (allRuntimesIsFulfilled) {
        const scriptNames = await getScriptNames(
          runtimeName,
          runtimeVersion,
          type === CustomFunctionsType.WEBSCRIPT,
        )

        const script = (await getExampleFile(
          runtimeName,
          scriptNames.mainScriptName,
          runtimeVersion,
        )) as string

        const manifest = (await getExampleFile(
          runtimeName,
          scriptNames.manifestName,
          runtimeVersion,
        )) as WebscriptManifest

        manifest.name = name
        manifest.runtimeVersion = runtimeVersion

        const dependenciesFile = await getExampleFile(
          runtimeName,
          scriptNames.projectScriptName,
          runtimeVersion,
        )

        let runtime = Object.values(allRuntimes)?.find(
          runtime =>
            runtime.name === runtimeName && runtime.version === runtimeVersion,
        )
        if (runtime === undefined) {
          const newRuntime = await client.registry.runtimes.get(
            runtimeName,
            runtimeVersion,
          )
          runtime = newRuntime.runtime
        }

        let dependencies = {}

        try {
          dependencies = formatDependencies(
            dependenciesFile,
            runtime.archiveFormat,
          )
        } catch {
          addToast('Failed to parse dependencies', { appearance: 'error' })
        }

        const data = {
          runtime,
          details: {
            [type]: { ...manifest },
          },
          manifest,
          dependencies,
          script,
          assets: {},
        }
        return data as unknown as WebscriptState | PlugV2Structure
      }
    },
    gcTime: 0,
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    if (
      !profile.isLoading &&
      profile?.data &&
      !example.isLoading &&
      example?.data
    ) {
      const data = cloneDeep(example.data)
      data.details[type].metadata.author = profile.data?.fullName
      queryClient.setQueryData(
        [EXAMPLE_FUNCTION_KEY, allRuntimesIsFulfilled],
        data,
      )
    }
  }, [profile, example])

  return {
    example,
  }
}

export const getScriptNames = async (
  name: string,
  version: string,
  isExample = false,
  type: CustomFunctionsType = CustomFunctionsType.PLUGIN,
) => {
  let mainScriptName: string, projectScriptName: string, manifestName: string
  const files = isExample
    ? await getExampleFileParams(name, version)
    : await getFunctionFileParams(name, version, type)
  files.forEach(roleName => {
    if (roleName.role === AssetRole.MAIN) {
      mainScriptName = roleName.name
    } else if (roleName.role === AssetRole.MANIFEST) {
      manifestName = roleName.name
    } else if (roleName.role === AssetRole.PROJECT) {
      projectScriptName = roleName.name
    }
  })
  return { mainScriptName, manifestName, projectScriptName }
}

export const getValidationSchema = async (
  runtimeName: string,
  runtimeVersion: string,
  assetRole,
) => {
  return await client.registry.runtimes.getSchema(
    runtimeName,
    runtimeVersion,
    assetRole,
  )
}

export const useCreateFunction = (
  isNew: boolean,
  type: CustomFunctionsType = CustomFunctionsType.WEBSCRIPT,
) => {
  const { addToast } = useToasts()

  return useMutation({
    mutationFn: async (arg: {
      data: Blob | string | FormData
      isLegacy?: boolean
    }) => {
      const { data, isLegacy } = arg
      let response: Record<string, any> = {}
      if (isLegacy) {
        response = await getFunctionClient(type, true).create(data)
      } else {
        const params =
          type === CustomFunctionsType.PLUGIN && isNew ? { draft: true } : {}
        response = await getFunctionClient(type).create(data, params)
      }

      return response
    },
    onSuccess: () =>
      addToast(
        `Successfully ${isNew ? 'created' : 'updated'} ${
          type === CustomFunctionsType.WEBSCRIPT ? 'webscript' : 'plugin'
        }`,
        { appearance: 'success' },
      ),

    onError: e => {
      const message =
        get(e, 'response.data.message') ?? get(e, 'response.data.error')
      addToast(`Something went wrong: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })
}

export const useCopyFunction = () => {
  const profile = useProfile()
  return useMutation({
    mutationFn: async (arg: {
      name: string
      sourceName: string
      sourceVersion: string
    }) => {
      const { name, sourceName, sourceVersion } = arg
      const response = await client.registry.plugs.create(undefined, {
        draft: true,
        name,
        author: profile?.data?.fullName,
        version: DEFAULT_VERSION,
        copy: `${sourceName}@${sourceVersion}`,
      })
      return response
    },
  })
}

export const useCreateFromExample = () => {
  const profile = useProfile()
  return useMutation({
    mutationFn: async (arg: {
      name: string
      runtime: string
      type: CustomFunctionsType
    }) => {
      const { name, runtime, type } = arg
      const queryParameters: CreateFromExampleParams = {
        name,
        author: profile?.data?.fullName,
        version: DEFAULT_VERSION,
        runtime,
        copy: `!example`,
      }

      if (type === CustomFunctionsType.PLUGIN) {
        queryParameters.draft = true
      }
      const copyFromExampleResponse = await getFunctionClient(type).create(
        undefined,
        queryParameters,
      )
      return copyFromExampleResponse
    },
  })
}

export const useCreateDraft = () => {
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const profile = useProfile()

  return useMutation({
    mutationFn: async (arg: {
      sourceName: string
      sourceVersion: string
      newVersion: string
      update?: Function
    }): Promise<{ name: string; version: string }> => {
      const { sourceName, sourceVersion, newVersion } = arg
      return await client.registry.plugs
        .create(undefined, {
          draft: true,
          author: profile?.data?.fullName,
          version: newVersion,
          copy: `${sourceName}@${sourceVersion}`,
        })
        .then(draftResponse => ({
          name: sourceName,
          version: newVersion,
          createEventLink: draftResponse?._links?.event?.href,
        }))
    },
    onSuccess: (draftState: {
      name: string
      version: string
      createEventLink?: string
    }) => {
      addToast('Successfully created draft', { appearance: 'success' })
      navigate(
        `/plugins/sensors/${draftState.name}?version=${draftState.version}`,
        {
          replace: true,
          state: { createEventLink: draftState.createEventLink },
        },
      )
    },
    onError: e => {
      const message =
        get(e, 'response.data.message') ??
        get(e, 'response.data.error') ??
        get(e, 'message')
      addToast(`Something went wrong: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })
}

export const usePublishFunction = () => {
  // No need to mutate query from here since we are doing it already
  // through usePublishModal callback (see PluginsButton component)
  return useMutation({
    mutationFn: async (arg: {
      name: string
      version: string
      comment: string
    }) => {
      const { name, version, comment } = arg
      const response = await client.registry.plugs.publishDraft(
        name,
        version,
        {},
        {
          comment,
        },
      )
      return response
    },
  })
}

export const useUpdateDraftAssets = () => {
  const { addToast } = useToasts()

  return useMutation({
    mutationFn: async (arg: { name: string; version: string; data: any }) => {
      const { name, version, data } = arg
      addToast('Updating assets...', { appearance: 'info' })
      return await client.registry.plugs.updateDraftAssets(name, version, data)
    },
  })
}

export const useUpdateMetadata = (type: CustomFunctionsType) => {
  const { addToast } = useToasts()

  return useMutation({
    mutationFn: async (arg: {
      name: string
      version: string
      metadata: any
    }) => {
      const { name, version, metadata } = arg
      await getFunctionClient(type).patchMetadata(name, version, metadata)
      return metadata
    },
    onSuccess: () =>
      addToast('Successfully updated metadata', { appearance: 'success' }),

    onError: e => {
      const message =
        get(e, 'response.data.message') ?? get(e, 'response.data.error')
      addToast(`Something went wrong: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })
}

export const usePatchManifest = () => {
  const { addToast } = useToasts()
  const { token } = useLogin()
  const mutateQueryPartialKey = useMutateQueryPartialKey()

  return useMutation({
    mutationFn: async (args: {
      name: string
      version: string
      manifest: any
      asyncMode: boolean
      updateStatusCallback: Function
      saveProgressCallback: Function
    }) => {
      const {
        name,
        version,
        manifest,
        asyncMode,
        updateStatusCallback,
        saveProgressCallback,
      } = args

      if (asyncMode) updateStatusCallback?.(DeploymentStatus.pending)
      saveProgressCallback(true /* starting update */)
      addToast('Updating manifest...', { appearance: 'info' })

      // When asyncMode is true, the manifest is deployed asynchronously
      // so we don't need to wait for the build & deploy to finish
      const params = {
        ...(!asyncMode && { deploy: false }),
      }
      // eslint-disable-next-line @typescript-eslint/return-await
      return client.registry.plugs
        .patchManifest(name, version, manifest, params)
        .then(patchManifestResponse => {
          const eventLink = patchManifestResponse?._links?.event?.href

          if (eventLink && asyncMode) {
            const eventSource = new EventSourcePolyfill(eventLink, {
              headers: { Authorization: `Bearer ${token}` },
              withCredentials: true,
            })

            eventSource.addEventListener(JobEventType.Completed, event => {
              const eventData = JSON.parse(event.data)
              if (eventData?.job?.type === JobType.Deploy) {
                updateStatusCallback?.(DeploymentStatus.deployed)
              } else if (eventData?.job?.type === JobType.Verify) {
                mutateQueryPartialKey(PLUGIN_VERSIONS)
                updateStatusCallback?.(DeploymentStatus.running)
                saveProgressCallback(false /* ended update */)
                addToast('Successfully updated manifest', {
                  appearance: 'success',
                })
                eventSource.close()
              }
            })

            eventSource.addEventListener(JobEventType.Failed, event => {
              updateStatusCallback?.(DeploymentStatus.failed)
              saveProgressCallback(false /* ended update */)
              mutateQueryPartialKey(PLUGIN_VERSIONS)
              const eventData = JSON.parse(event.data)
              const message = get(eventData, 'data.failedReason', '')
              if (!message.includes(REVISION_ERROR_PATTERN)) {
                addToast(`Something went wrong. ${message}`, {
                  appearance: 'error',
                })
              }
              eventSource.close()
            })

            eventSource.addEventListener(JobEventType.Close, () => {
              saveProgressCallback(false /* ended update */)
              eventSource.close()
            })
          }

          return [patchManifestResponse, args]
        })
        .catch(err => {
          args.saveProgressCallback(false /* ended update */)
          throw err
        })
    },
    onSuccess: ([patchManifestResponse, args]) => {
      if (!args.asyncMode) {
        args.saveProgressCallback(false /* ended update */)
        addToast('Successfully updated manifest', {
          appearance: 'success',
        })
      }

      return patchManifestResponse
    },
    onError: err => {
      const message =
        get(err, 'response.data.message') ?? get(err, 'response.data.error')
      addToast(`Failed to update manifest: ${message}`, { appearance: 'error' })
    },
  })
}

export const useDownload = (type: CustomFunctionsType) => {
  const { addToast } = useToasts()

  return useMutation({
    mutationFn: async (arg: {
      name: string
      version: string
      plugType?: string
    }) => {
      const { name, version, plugType } = arg
      const response = await getFunctionClient(type).content(
        name,
        version,
        {},
        {
          headers: { Accept: MIME_TYPE.GZIP },
        },
      )

      return { response, type, version, name, plugType }
    },
    onSuccess: ({ response, type, version, name, plugType }) => {
      const fileName =
        type === CustomFunctionsType.PLUGIN
          ? `${plugType}-${name}-${version}`
          : name
      createFileDownload(`${fileName}.gz`, response, MIME_TYPE.GZIP)
    },
    onError: error => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to download: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })
}

export const usePluginVersions = options => {
  const { limit, page, name } = options ?? {}
  const key = [PLUGIN_VERSIONS, name, limit, page]
  const { isLoading, data, error } = useQuery({
    queryKey: key,
    queryFn: () => fetchPlugVersions(options),
    gcTime: 0,
  })

  return {
    loading: isLoading,
    error,
    versions: data?.versions,
    count: data?.count,
  }
}

export const useTakeOwnership = () => {
  return useMutation({
    mutationFn: async (arg: {
      name: string
      version: string
      fileName: string
      data: any
    }) => {
      const { name, version, fileName, data } = arg
      const response = await client.registry.plugs.updateDraftAsset(
        name,
        version,
        fileName,
        data,
        {
          chown: true,
        },
      )
      return response
    },
  })
}

export const useProtect = (type: CustomFunctionsType) => {
  const { addToast } = useToasts()
  const mutateQueryPartialKey = useMutateQueryPartialKey()

  const protectOrUnprotectPlug = async ({
    name,
    enable,
  }: ProtectPlugResult) => {
    await client.registry.plugs.protectAll(name, { enable })
    return { name, enable }
  }

  const protectOrUnprotectWebscript = async ({
    name,
    version,
    enable,
  }: ProtectWebscriptResult) => {
    await client.registry.webscripts.protect(name, version, { enable })
    return { name, version, enable }
  }

  return useMutation({
    mutationFn:
      type === CustomFunctionsType.PLUGIN
        ? protectOrUnprotectPlug
        : type === CustomFunctionsType.WEBSCRIPT
        ? protectOrUnprotectWebscript
        : undefined,

    onSuccess: ({
      name,
      version,
      enable,
    }: {
      name: string
      version?: string
      enable: boolean
    }) => {
      const message = `Successfully ${
        enable ? 'protected' : 'unprotected'
      } ${type} ${name} ${version ? `version ${version}` : ''}`
      addToast(message, { appearance: 'success' })

      if (type === CustomFunctionsType.PLUGIN) {
        mutateQueryPartialKey(PLUGIN_KEY)
        mutateQueryPartialKey(FUNCTION_KEY)
      } else {
        mutateQueryPartialKey(WEBSCRIPTS_KEY)
      }
    },

    onError: (error, { name, enable }) => {
      const actionWord = enable ? 'protect' : 'unprotect'
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to ${actionWord} ${type} ${name}: ${message}`, {
        appearance: 'error',
      })
    },
  })
}

const formatDependencies = (dependencies, runtime: string) => {
  if (runtime === FUNCTION_RUNTIME.NODE) {
    return dependencies?.dependencies
  } else if (runtime === FUNCTION_RUNTIME.PYTHON) {
    if (!isEmpty(dependencies)) {
      const dependenciesArray = (dependencies as string).split('\n')
      return dependenciesArray.filter(dependency => dependency)
    }
    return dependencies
  } else {
    throw new Error('Unsupported runtime')
  }
}

const fetchPlugVersions = async options => {
  if (options.name) {
    const versions = await client.registry.plugs.listVersions(options.name, {
      status: 'any',
      limit: options.limit,
      page: options.page,
    })
    return { count: versions.count, versions: versions.entities ?? [] }
  } else {
    return { count: 0, versions: [] }
  }
}

const getExampleFile = async (
  runtime: string,
  path: string,
  version: string,
): Promise<string | WebscriptManifest> => {
  return await ((await client.registry.runtimes.example.getFileContent(
    runtime,
    version,
    path,
  )) as Promise<string | WebscriptManifest>)
}

const getExampleFileParams = async (
  runtime: string,
  version: string,
): Promise<IRoleName[]> => {
  return await ((
    await client.registry.runtimes.example.listContent(runtime, version)
  ).assets as Promise<IRoleName[]>)
}

const getFunctionFileParams = async (
  plugName: string,
  plugVersion: string,
  type,
): Promise<IRoleName[]> => {
  return await ((
    await getFunctionClient(type).listContent(plugName, plugVersion)
  ).assets as Promise<IRoleName[]>)
}

const fetchAdditionalAssets = async (
  name: string,
  version: string,
  type = CustomFunctionsType.WEBSCRIPT,
) => {
  const data = await getFunctionClient(type).listContent(name, version)
  const additionalAssets = {}

  if (isArray(data.assets) && !isEmpty(data.assets)) {
    data.assets.forEach(async asset => {
      if (asset.role === AssetRole.OTHER) {
        additionalAssets[asset.name] = { isInitial: true }
      }
    })
  }

  return additionalAssets
}

const fetchCustomFunction = (
  name: string,
  version: string = undefined,
  type = CustomFunctionsType.WEBSCRIPT,
) => {
  if (version) {
    return getFunctionClient(type).get(name, version, {
      showTags: FUNCTION_TAGS_RENDERING.INLINE,
    })
  }
  return getFunctionClient(type).getLatest(name, {
    showTags: FUNCTION_TAGS_RENDERING.INLINE,
  })
}

const pluralize = (word: string) => `${word}s`

const getFunctionClient = (type: CustomFunctionsType, legacy = false) =>
  legacy ? client[pluralize(type)] : client.registry[pluralize(type)]

enum AssetRole {
  MAIN = 'main',
  MANIFEST = 'manifest',
  PROJECT = 'project',
  OTHER = 'other',
}

interface IRoleName {
  role: AssetRole
  title?: string
  name: string
}

interface CreateFromExampleParams {
  name: string
  author: string
  version: string
  runtime: string
  copy: string
  draft?: boolean
}

interface ProtectPlugResult {
  name: string
  enable: boolean
}

interface ProtectWebscriptResult {
  name: string
  version: string
  enable: boolean
}
